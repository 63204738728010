@charset "UTF-8";
/*!
 *  dotudash ~ main/homepage
 *
 *  @author   Михаил Драгункин <contact@md.land>
 *  @version  1.0.0
 */
/* @group homepage */
.homepage-body_first-visit .header, .homepage-body_first-visit .footer {
  animation-delay: 6000ms;
  animation-duration: 3250ms; }

.homepage-body_first-visit .homepage .homepage__section {
  animation-delay: 5500ms;
  animation-duration: 2500ms; }

.homepage__section {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  width: 100%; }

.homepage__container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px; }
  @media (min-width: 544px) {
    .homepage__container {
      padding-right: 15px;
      padding-left: 15px; } }
  @media (min-width: 768px) {
    .homepage__container {
      padding-right: 15px;
      padding-left: 15px; } }
  @media (min-width: 992px) {
    .homepage__container {
      padding-right: 15px;
      padding-left: 15px; } }
  @media (min-width: 1200px) {
    .homepage__container {
      padding-right: 15px;
      padding-left: 15px; } }
  @media (min-width: 544px) {
    .homepage__container {
      width: 576px;
      max-width: 100%; } }
  @media (min-width: 768px) {
    .homepage__container {
      width: 720px;
      max-width: 100%; } }
  @media (min-width: 992px) {
    .homepage__container {
      width: 100%;
      max-width: 100%; } }

@media (max-width: 991px) {
  .homepage__greeting {
    font-size: 1.3em;
    display: block;
    margin-bottom: .5em; } }

.homepage a {
  /* @group themes */
  /* @end */ }
  .homepage a {
    /* @group red theme */
    /* @end */ }
    .html_theme_red .homepage a {
      color: #ff0000; }
      .html_theme_red .homepage a:hover {
        color: #cc0000; }
  .homepage a {
    /* @group orange theme */
    /* @end */ }
    .html_theme_orange .homepage a {
      color: #ffa500; }
      .html_theme_orange .homepage a:hover {
        color: #cc8400; }
  .homepage a {
    /* @group purple theme */
    /* @end */ }
    .html_theme_purple .homepage a {
      color: #7400bf; }
      .html_theme_purple .homepage a:hover {
        color: #55008c; }
  .homepage a {
    /* @group green theme */
    /* @end */ }
    .html_theme_green .homepage a {
      color: #08bb33; }
      .html_theme_green .homepage a:hover {
        color: #068a26; }
  .homepage a {
    /* @group java theme */
    /* @end */ }
    .html_theme_java .homepage a {
      color: #00aaaa; }
      .html_theme_java .homepage a:hover {
        color: #007777; }
  .homepage a {
    /* @group lightblue theme */
    /* @end */ }
    .html_theme_lightblue .homepage a {
      color: #0070df; }
      .html_theme_lightblue .homepage a:hover {
        color: #0056ac; }
  .homepage a {
    /* @group black theme */
    /* @end */ }
    .html_theme_black .homepage a {
      color: #000000; }
      .html_theme_black .homepage a:hover {
        color: black; }
  .homepage a:hover {
    text-decoration: underline; }

/* @end */
